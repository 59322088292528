import * as React from "react";
import * as styles from './BookReview.module.css';
import data from '../../assets/data/data';

export default function BookReview({ title }) {
    const [bookReview, setBookReview] = React.useState([]);

    React.useEffect(() => {
        const parsedTitle = title.replaceAll('-', ' ').toLowerCase();
        const bookReview = data.bookReviews.filter(review => review.title.replaceAll(',','').toLowerCase() === parsedTitle);
        setBookReview(bookReview[0]);
    }, [title]);

    return (
        <div className={styles.bookReview}>
            <div className={styles.details}>
                <h1>{bookReview?.date}</h1>
                <span className={styles.bookTitle}>{bookReview?.title}</span> - {bookReview?.author}
                <p className={styles.rating}>{bookReview?.rating}</p>
            </div>

            {bookReview?.review?.map(e => {
                if (typeof e === "string") {
                    return <p>{e}</p>
                } else if (e.type === "linkout") {
                    return <a href={e.url}>{e.cta}</a>
                } else if (e.type === "list") {
                    return <ul>
                        {e.items.map(item => (<li>{item}</li>))}
                    </ul>
                }

                return null;
            })}
        </div>
    )
};
